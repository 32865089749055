import { HomeContainer } from "../components/home/container/HomeContainer"
import { ServicesContainer } from "../components/home/container/ServicesContainer"
import { RoomsContainer } from "../components/home/container/RoomsContainer"
import { ContactContainer } from "../components/home/container/ContactContainer"
import { Map } from "../components/home/container/Map"
import React from "react"
import { Screen } from "../components/general/Screen"
import { View } from "../components/general/View"
import { useWindowDimensions } from "../hooks/WindowDimensions"

export function HomeScreen() {
  const { width } = useWindowDimensions()

  return (
    <Screen>
      <HomeContainer />
      <View style={{ width: width > 550 ? "70%" : "95%", alignItems: "center", flexDirection: "column" }}>
        <ServicesContainer />
        <RoomsContainer />
        <ContactContainer />
      </View>
      <Map />
    </Screen>
  )
}
