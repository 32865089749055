import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import "./services/localization/i18n"
import "./assets/css/open-iconic-bootstrap.min.css"
import "./assets/css/animate.css"
import "./assets/css/owl.carousel.min.css"
import "./assets/css/owl.theme.default.min.css"
import "./assets/css/magnific-popup.css"
import "./assets/css/aos.css"
import "./assets/css/ionicons.min.css"
import "./assets/css/bootstrap-datepicker.css"
import "./assets/css/jquery.timepicker.css"
import "./assets/css/flaticon.css"
import "./assets/css/icomoon.css"
import "./assets/css/style.css"

import ReactGA from "react-ga4";
import gtag from "react-ga4/types/gtag";

ReactGA.initialize([{
        trackingId: 'GTM-T6GJFWMN',
        gtagOptions: { }
    }]);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
