import React, { CSSProperties, MouseEvent, ReactNode } from "react"

export interface ViewProps {
  hoverIn?: (event: MouseEvent<HTMLElement>) => void
  hoverOut?: (event: MouseEvent<HTMLElement>) => void
  children?: ReactNode
  style?: CSSProperties
  className?: string
  id?: string
}

export function View({ children, style, hoverIn, hoverOut, className, id }: ViewProps) {
  return (
    <div
      className={className}
      id={id}
      style={{ display: "flex", boxSizing: "border-box", borderWidth: 0, borderStyle: "solid", ...style }}
      onMouseEnter={hoverIn}
      onMouseLeave={hoverOut}>
      {children}
    </div>
  )
}
