import React from "react"
import { ServiceItem } from "../../list/ServiceItem"
import { useTranslation } from "react-i18next"
import { View } from "../../general/View"

export function ServicesContainer() {
  const { t } = useTranslation()
  const services: { heading: string; description: string; icon: string }[] = [
    {
      heading: "rooms_apartments",
      description: "rooms_apartments_desc",
      icon: "flaticon-double-bed",
    },
    {
      heading: "wifi",
      description: "wifi_desc",
      icon: "flaticon-wifi",
    },
    {
      heading: "buffet_breakfast",
      description: "buffet_breakfast_desc",
      icon: "flaticon-dinner",
    },
    {
      heading: "credit_card",
      description: "credit_card_desc",
      icon: "flaticon-credit-card",
    },
    {
      heading: "sauna",
      description: "sauna_desc",
      icon: "flaticon-pool",
    },
    {
      heading: "parking",
      description: "parking_desc",
      icon: "flaticon-parking",
    },
  ]

  return (
    <View id="services" style={{ width: "100%", paddingBottom: 20 }}>
      <View style={{ flexDirection: "column" }}>
        <View style={{ paddingBottom: 10, paddingTop: 10 }}>
          <View style={{ flexDirection: "column" }}>
            <p style={{ fontSize: 24, color: "#999999" }}>{t("services")}</p>
            <h2>{t("facilities_services")}</h2>
          </View>
        </View>
        <View style={{ flexWrap: "wrap", justifyContent: "center" }}>
          {services.map((value) => (
            <ServiceItem key={value.heading} description={value.description} heading={value.heading}
                         icon={value.icon} totalItems={services.length} />
          ))}
        </View>
      </View>
    </View>
  )
}
