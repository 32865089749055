import React from "react"
import { useWindowDimensions } from "../../../hooks/WindowDimensions"
import { useTranslation } from "react-i18next"

export function Map() {
  const { width } = useWindowDimensions()
  const { i18n } = useTranslation()
  return (
    <iframe
      allowFullScreen={false}
      height="450"
      id={"map"}
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
      src={`https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2555.7352645624646!2d16.2713504!3d50.1660876!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470ddfc8c29fa7db%3A0x2cc4952273227248!2sHotel%20HERMAN!5e0!3m2!1scs!2scz!4v1697790130392!5m2!1scs!2scz&hl=${i18n.language ?? "cs"}`}
      style={{ border: 0 }}
      width={width}
    />
  )
}
