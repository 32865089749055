import { useTranslation } from "react-i18next"
import { View } from "../general/View"
import { useWindowDimensions } from "../../hooks/WindowDimensions"

export interface ServiceItemProps {
  icon: string
  heading: string
  description: string
  totalItems: number;
}

export function ServiceItem({ icon, heading, description, totalItems }: ServiceItemProps) {
  const { t } = useTranslation()
  const { width } = useWindowDimensions()


  const itemsPerRow = Math.ceil(totalItems / 2)
  const itemWidth = `${100 / itemsPerRow}%`

  return (
    <View style={{ flexGrow: 1, maxWidth: 500, margin: 10, width: width < 1000 ? "100%" : itemWidth }}>
      <View>
        <View style={{ flexDirection: "row" }}>
          <View className="icon" style={{ justifyContent: "center", alignItems: "center" }}>
            <span className={icon} style={{ fontSize: 50, padding: 10, color: "red" }}></span>
          </View>
          <View style={{ flex: 1, flexDirection: "column" }}>
            <h3>{t(heading)}</h3>
            <p>{t(description)}</p>
          </View>
        </View>
      </View>
    </View>
  )
}
