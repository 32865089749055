import React, { CSSProperties } from "react"
import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { ArrowLeft, ArrowRight } from "@mui/icons-material"

/**
 * https://react-bootstrap.netlify.app/docs/components/carousel/
 */
export function HomeContainer() {

  const arrowStyles: CSSProperties = {
    position: "absolute",
    zIndex: 2,
    top: "calc(50% - 15px)",
    width: 30,
    height: 30,
    cursor: "pointer",
    color: "#fff",
  }

  const images: { imageUrl: string; heading: string }[] = [
    {
      imageUrl:
        "https://ik.imagekit.io/jx1rz1wlu/herman/fotos/room11_1_4Nyrdxy3sZ.jpg?updatedAt=1697899510663",
      heading: "welcome_1",
    },
    {
      imageUrl:
        "https://ik.imagekit.io/jx1rz1wlu/herman/fotos/room5_3_JA460iHrl.jpg?updatedAt=1697899502339",
      heading: "welcome_2",
    },
  ]
  return (
    <div className="block-31" id="home" style={{ position: "relative", paddingBottom: 20 }}>
      <Carousel
        autoPlay
        infiniteLoop
        stopOnHover
        animationHandler={"fade"}
        dynamicHeight={true}
        interval={10000}
        swipeScrollTolerance={5}
        transitionTime={500}
        width={"100%"}
        renderArrowNext={(clickHandler, hasNext) => hasNext &&
          <ArrowRight style={{ ...arrowStyles, right: 15 }} onClick={clickHandler} />}
        renderArrowPrev={(clickHandler, hasPrev) => hasPrev &&
          <ArrowLeft style={{ ...arrowStyles, left: 15 }} onClick={clickHandler} />}
      >
        {images.map((value) => (
          <div key={value.heading} style={{ height: "100vh" }}>
            <img
              alt={value.heading}
              src={value.imageUrl}
            />
          </div>
        ))}
      </Carousel>
    </div>
  )
}
