import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { View } from "../../general/View"
import jsonData from "../../../assets/data/rooms.json"
import { useNavigate } from "react-router-dom"
import { Pressable } from "../../general/Pressable"
import { FrameGrid } from "@egjs/react-grid"
import { Room } from "../../../screens/BookingScreen"
import { BlurredUpImage } from "../../general/BlurredUpImage"

interface RoomDataItem {
  img: string;
  title: string;
}

function srcset(image: string, size: number, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  }
}

export function RoomsContainer() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [rooms, setRooms] = useState<Room[]>(jsonData as Room[] ?? [])
  const [images, setImages] = useState<RoomDataItem[]>([])
  const [frame, setFrame] = useState<number[][]>([
    [1, 1, 3, 4],
    [1, 1, 2, 2],
    [6, 6, 7, 7],
    [5, 8, 7, 7]])


  useEffect(() => {
    const array: RoomDataItem[] = []
    rooms.forEach((room) => {
      room.imageSourceList.forEach((image) => {
        array.push({ img: image, title: room.roomName })
      })
    })
    setImages(mixArray(array))

    if (isEven(array.length)) {
      const updatedFrame: number[][] = [...frame]
      updatedFrame[updatedFrame.length - 1][1] = 5

      if (array.length % updatedFrame.length) {
        const count = (array.length % updatedFrame.length)
        const largest = findLargestNumberInMatrix(updatedFrame)
        for (let i = 0; i < count; i++) {
          updatedFrame.push([largest + 1 + i, largest + 2 + i, largest + 3 + i, largest + 4 + i])
        }
      }

      setFrame(updatedFrame)
    }
  }, [images.length, rooms])

  useEffect(() => {
    setRooms(jsonData)
  }, [jsonData])

  function isEven(n: number) {
    return n % 2 == 0
  }

  function mixArray<T>(array: T[]) {
    return array.sort(() => Math.random() - 0.5)
  }

  function findLargestNumberInMatrix(matrix: number[][]) {
    let largest = 0
    matrix.forEach((row) => {
      row.forEach((value) => {
        if (value > largest) {
          largest = value
        }
      })
    })
    return largest
  }

  return (
    <View id="rooms" style={{ paddingBottom: 20 }}>
      <View style={{ flexDirection: "column" }}>
        <View style={{ paddingBottom: 20 }}>
          <View style={{ flexDirection: "column" }}>
            <p style={{ fontSize: 24, color: "#999999" }}>{t("featured_rooms")}</p>
            <h2>{t("rooms_and_apartments")}</h2>
            <p>{t("rooms_and_apartments_desc")}</p>
          </View>
        </View>
        <View style={{ width: "100%", justifyContent: "center" }}>
          <View
            style={{
              flexDirection: "column",
              alignItems: "center",
              width: "90%",
              justifyContent: "center",
            }}>

            <FrameGrid
              isEqualSize
              className="container"
              defaultDirection={"end"}
              frame={frame}
              gap={5}
              rectSize={0}
            >
              {images.map((value, index) => <BlurredUpImage key={value.title + index} alt={value.title}
                                                            source={value.img} />)}
            </FrameGrid>
            <Pressable
              style={{ width: "100%", justifyContent: "center", alignItems: "center", backgroundColor: "#ff0000" }}
              onPress={() => navigate("/booking")}>
              <h3 style={{ color: "#fff" }}>{t("book")}</h3>
            </Pressable>
          </View>
        </View>
      </View>
    </View>
  )
}
