import { useTranslation } from "react-i18next"
import { View } from "../general/View"
import EmailIcon from "@mui/icons-material/Email"
import GavelIcon from "@mui/icons-material/Gavel"
import LocalPhoneIcon from "@mui/icons-material/LocalPhone"
import RoomIcon from "@mui/icons-material/Room"
import WatchLaterIcon from "@mui/icons-material/WatchLater"


export function ContactInfo() {
  const { t } = useTranslation()
  return (
    <View style={{ paddingLeft: 50 }}>
      <View style={{ flexDirection: "column" }}>
        <View>
          <RoomIcon style={{ color: "#999999" }} />
          <p>
            <a
              href="https://www.google.com/maps/place/Hotel+HERMAN/@50.1660876,16.2691617,17z/data=!3m1!4b1!4m8!3m7!1s0x470ddfc8c29fa7db:0x2cc4952273227248!5m2!4m1!1i2!8m2!3d50.1660876!4d16.2713504"
              rel="noreferrer"
              style={{ color: "#1e1e1e" }}>
              {"Jiráskova 316, Rychnov nad Kněžnou 516 01, Czech republic"}
            </a>
          </p>
        </View>
        <View>
          <LocalPhoneIcon style={{ color: "#999999" }} />
          <p>
            <a href="tel:00420724191395" style={{ color: "#1e1e1e" }}>
              {"(+420) 724 191 395"}
            </a>
          </p>
        </View>
        <View>
          <EmailIcon style={{ color: "#999999" }} />
          <p>
            <a href="mailto:info@hotelherman.cz" style={{ color: "#1e1e1e" }}>
              {"info@hotelherman.cz"}
            </a>
          </p>
        </View>
        <View>
          <WatchLaterIcon style={{ color: "#999999" }} />
          <p>
            {t("monday_sunday")}&nbsp;&nbsp;
            {t("footer_time")}
          </p>
        </View>
        <View>
          <GavelIcon style={{ color: "#999999" }} />
          <p>{"IČO: 45537381"}</p>
        </View>
        <View>
          <GavelIcon style={{ color: "#999999" }} />
          <p>{"DIČ: CZ45537381"}</p>
        </View>
      </View>
    </View>
  )
}
