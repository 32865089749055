import { useTranslation } from "react-i18next"
import React, { CSSProperties, ReactNode, useEffect, useRef, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { View } from "./general/View"
import LocalPhoneIcon from "@mui/icons-material/LocalPhone"
import { useHeader } from "../hooks/useHeader"
import { useWindowDimensions } from "../hooks/WindowDimensions"
import { Pressable } from "./general/Pressable"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import { Menu, MenuItem } from "@mui/material"
import { animateScroll as scroll } from "react-scroll"
import { Bed, Call, CardMembership, Home, RoomService } from "@mui/icons-material"
import ReactGA from "react-ga4";


export function Header() {
  const { setDimensions } = useHeader()
  const { width, height } = useWindowDimensions()
  const ref = useRef<HTMLElement>(null)
  const location = useLocation()
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [anchorElLang, setAnchorElLang] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const openLang = Boolean(anchorElLang)
  const { t, i18n } = useTranslation()
  const [isHomeScreen, setIsHomeScreen] = useState(false)

  const languages = ["en", "cs", "de"]
  const navigationList: {
    icon?: ReactNode,
    nav: string
  }[] = [{ nav: "home", icon: <Home /> }, { nav: "services", icon: <RoomService /> }, {
    nav: "rooms",
    icon: <Bed />,
  }, { nav: "contact", icon: <Call /> }]

  useEffect(() => {
    if (ref.current) {
      const { height, width, top, left, x, y } = ref.current.getBoundingClientRect()
      setDimensions({ height, width, top, left, x, y })
    }
  }, [width, height])

  useEffect(() => {
    if (location.pathname === "/") {
      setIsHomeScreen(true)
    } else {
      setIsHomeScreen(false)
    }
  }, [location.pathname])

  const changeLng = async (lng: string) => {
    localStorage.setItem("language", lng)
    await i18n.changeLanguage(lng)
  }

  const scrollToElement = (elementId: string) => {
    const doc = document.getElementById(elementId)
    doc && scroll.scrollTo(doc.offsetTop, {
      duration: 800,
      smooth: "easeInOutQuart",
    })
  }

  const stringChange = (str: string) => {
    switch (str) {
      case "en":
        return "us"
      case "cs":
        return "cz"
      case "de":
        return "de"
      default:
        return str
    }
  }
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClickLang = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElLang(event.currentTarget)
  }

  const handleCloseLang = () => {
    setAnchorElLang(null)
  }

  const navigationStyle: CSSProperties = {
    flexGrow: 1,
    color: "#fff",
    cursor: "pointer",
  }


  return (
    <>
      <nav
        ref={ref}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 2,
          position: "fixed",
          width: "100vw",
          height: 70,
          backgroundColor: "rgb(114, 114, 114)",
          paddingLeft: "1vw",
          paddingRight: "1vw",
          boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.25)",
        }}
      ><View style={{ flexDirection: "row", width: "100%", justifyContent: "space-between", alignItems: "center" }}>
        <View
          style={{
            flexDirection: "row",
            display: "flex",
            justifyContent: "center",
          }}
        >

          <a style={{ color: "#fff", fontSize: 24, paddingRight: 10, cursor: "pointer" }}
             onClick={() => isHomeScreen ? scrollToElement("home") : navigate("/", { state: "#home" })}>
            {"HOTEL HERMAN"}
          </a>

          {width > 500 && (<div
            style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "center", alignItems: "center",
            }}
          >
            <div style={{ paddingRight: 10, display: "flex", alignItems: "center" }}><LocalPhoneIcon
              style={{ color: "#fff" }} /></div>
            <a href="tel:00420724191395" style={{ color: "#fff", fontSize: 24, cursor: "pointer" }}>
              {"+420 724 191 395"}
            </a>
          </div>)}
        </View>
        <div
          style={{
            display: "flex",
            width: width > 880 ? "50%" : undefined,
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >{width > 880 ? <>{navigationList.map((value) => (
            <div key={value.nav} style={navigationStyle}
                 onClick={() => isHomeScreen ? scrollToElement(value.nav) : navigate(`/#${value.nav}`)}>
              {t(`menu.${value.nav}`)}
            </div>
          ))}
          </> :
          <Pressable id={"long-button"} style={{ alignItems: "center" }} onPress={handleClick}><MoreVertIcon
            style={{ color: "#fff", marginRight: 8 }} /></Pressable>}
          <Pressable id={"long-button2"} style={{ alignItems: "center" }} onPress={handleClickLang}><span
            className={`flag-icon flag-icon-${stringChange(i18n.language)}`}
          /></Pressable>
        </div>
      </View>
      </nav>
      <Menu
        anchorEl={anchorElLang}
        id="long-menu2"
        open={openLang}
        MenuListProps={{
          "aria-labelledby": "long-button2",
        }}
        onClose={() => handleCloseLang()}
      >
        {languages.map((value) => (
          <MenuItem key={value} onClick={async () => {
            await changeLng(value)
            handleClose()
          }}>
            <div
              className={`flag-icon flag-icon-${stringChange(value)}`}
            />
            {t(`languages.long.${value}`)}
          </MenuItem>
        ))}
      </Menu>
      <Menu
        anchorEl={anchorEl}
        id="long-menu"
        open={open}
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        onClose={() => handleClose()}
      >
        {[...navigationList].map((value) => (
          <MenuItem key={value.nav} onClick={() => {
            navigate(`/#${value.nav}`)
            handleClose()
          }}>
            <View>{value.icon}{t(`menu.${value.nav}`)}</View>
          </MenuItem>
        ))}
      </Menu></>
  )
}
