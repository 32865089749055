import { useEffect, useState } from "react"

export type Dimensions = {
  width: number
  height: number
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height,
  }
}

/**
 * Hook to get the current window dimensions
 */
export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState<Dimensions>(getWindowDimensions())

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return windowDimensions
}
