import { ViewProps } from "./View"

export interface PressableProps extends ViewProps {
  onPress?: (event: any) => void
  disabled?: boolean
}

export function Pressable({ onPress, children, style, disabled, hoverOut, hoverIn }: PressableProps) {
  return (
    <div
      style={{
        display: "flex",
        boxSizing: "border-box",
        borderWidth: 0,
        borderStyle: "solid",
        cursor: disabled ? "default" : "pointer",
        ...style,
      }}
      onClick={(event) => !disabled && onPress && onPress(event)}
      onMouseEnter={hoverIn}
      onMouseLeave={hoverOut}>
      {children}
    </div>
  )
}
