import { ContactInfo } from "../../list/ContantInfo"
import React from "react"
import { useTranslation } from "react-i18next"
import { View } from "../../general/View"

export function ContactContainer() {
  const { t } = useTranslation()
  return (
    <View id="contact" style={{ width: "100%", paddingBottom: 20 }}>
      <View style={{ flexDirection: "column", width: "100%" }}>
        <p style={{ fontSize: 24, color: "#999999" }}>{t("footer_contact")}</p>
        <ContactInfo />
      </View>
    </View>
  )
}
