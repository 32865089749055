import i18next, { Resource } from "i18next"
import en from "./en/translation.json"
import cs from "./cs/translation.json"
import de from "./de/translation.json"
import { initReactI18next } from "react-i18next"
import { i18nextPlugin } from "translation-check"
import LanguageDetector from "i18next-browser-languagedetector"

export const languageResources: Resource = {
  en: { translation: en },
  cs: { translation: cs },
  de: { translation: de },
}

export default i18next
  .use(LanguageDetector)
  .use(i18nextPlugin)
  .use(initReactI18next)
  .init({
    fallbackLng: "cs",
    lng: "cs",
    debug: process.env.NODE_ENV === "development",
    resources: languageResources,
  })
