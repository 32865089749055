import React, { CSSProperties } from "react"
import useProgressiveImg from "../../hooks/useProgressiveImg"

export const BlurredUpImage = ({ source, style, alt }: {
  source: string,
  alt?: string,
  style?: CSSProperties
}) => {
  const split = source.split("fotos/")
  const low = split[0] + "fotos/tr:w-150,h-100/" + split[1]
  const [src, { blur }] = useProgressiveImg(low, source)

  return (
    <img
      alt={alt ?? src}
      src={src}
      style={{
        width: 200,
        objectFit: "cover",
        filter: blur ? "blur(20px)" : "none",
        transition: blur ? "none" : "filter 0.3s ease-out",
        ...style,
      }}
    />
  )
}

